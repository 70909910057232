import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`UI Kits Setup`}</h2>
    <p>{`Legion design Libraries are separated into multiple projects. The first step, make sure you were invited to project file from the `}<a parentName="p" {...{
        "href": "https://forms.gle/vNvoJ5t6FDM7D1VD8"
      }}><strong parentName="a">{`Request Form`}</strong></a>{`, then check the available library for use in the table of list library or the project you assigned, and select the design library before you start designing.`}</p>
    <h2>{`Connect your foundation`}</h2>
    <p>{`Begin by connecting your design foundation to start utilizing UI Kits in your product design. This section will guide you through the initial steps required to integrate design foundations with UI kits, ensuring that your product has a solid groundwork before proceeding further in design development.`}</p>
    <h3>{`1. Color Harmony for Foundation`}</h3>
    <p>{`The first step in integrating your design with the brand or product identity is by changing the colors on your foundation with the predefined color palette. By doing this, you can ensure that your design remains consistent with the brand image and product goals that have been established previously.`}</p>
    <h3>{`2. Typography Consistency with Foundation`}</h3>
    <p>{`After updating the colors, the next step is to rearrange the typography by adopting the predefined font styles for your brand or product. By using consistent typography, you can strengthen the brand identity and enhance the visual impression of your product.`}</p>
    <h3>{`3. Integrating Other Foundation Properties`}</h3>
    <p>{`In addition to colors and typography, don’t forget to implement other properties of your design foundation. These include properties such as size, padding, margin, and others that can ensure the alignment and consistency of your product design with the UI kits used.`}</p>
    <h3>{`4. Final Review and Publication`}</h3>
    <p>{`Before publishing, conduct a final check to ensure all elements adhere to established standards. Once confirmed, proceed to publish the design, making it available for users to utilize.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Create a New Library: Create and publish your tribe or squad in the Following Steps using Legion UI Library - Internal. Then, contact the core team to assess your foundation before registering to Legion multi-theme to develop and create a website platform.`}</p>
    </InlineNotification>
    <h2>{`Connect to design token with asset code`}</h2>
    <p>{`To integrate your design effectively, connect your prepared foundation and UI kits with design tokens using asset code. This ensures that every design element is directly linked to your established design system, providing flexibility and consistency in your product’s development.`}</p>
    <h3>{`1. Request workshop implementation`}</h3>
    <p>{`Request a workshop for more effective design implementation. Here, you can collaborate with the team to plan and conduct workshops tailored to your project needs, ensuring a deep understanding of the design system and its implementation steps.`}</p>
    <h3>{`2. Import color and typography`}</h3>
    <p>{`Import predefined color palettes and font styles. By importing pre-configured color and typography settings, you can quickly start using the design system in your project, enhancing consistency and efficiency in the design process.`}</p>
    <h3>{`3. Push and see realtime updates`}</h3>
    <p>{`Push your design changes and see updates in real-time. With this feature, you can quickly implement changes into your design and see the result instantly, facilitating more effective team collaboration and quick decision-making.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Create a New Library: Create and publish your tribe or squad in the Following Steps using Legion UI Library - Internal. Then, contact the core team to assess your foundation before registering to Legion multi-theme to develop and create a website platform.`}</p>
    </InlineNotification>
    <h2>{`Recommended Figma Plugins`}</h2>
    <p>{`Some recommended plugins to simplify your workflow, task, accessibility checking, and design quality:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/1103648664059257410/supa-palette"
        }}><strong parentName="a">{`Supa Palette`}</strong></a>{`: Palette editor/generator for quickly beautiful, harmonious, and accessible palettes in a short time.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/818203235789864127/batch-styler"
        }}><strong parentName="a">{`Batch Styler`}</strong></a>{`: Change values of multiple (text and color) styles at once but don’t want to go through the process of changing each text style.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/931280467863251825/adee-comprehensive-accessibility-tool"
        }}><strong parentName="a">{`Adee Comprehensive Accessibility Tool`}</strong></a>{`: Test color contrast, simulate 8 color blind simulations, and test touch target sizes to ensure they meet the various device standards.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/732603254453395948/stark-contrast-accessibility-tools"
        }}><strong parentName="a">{`Stark - Contrast & Accessibility Tools`}</strong></a>{`: Contrast Checker, Focus Order, Alt-Text Annotations, Vision Simulator, and more, all in one place.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/798826066406007173/ditto-manage-copy-from-design-to-production"
        }}><strong parentName="a">{`Ditto`}</strong></a>{`: Single source of truth for everyone touching copy, from writers to designers to engineers.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      